/*Typeface Imorts*/
@font-face {
    font-family: 'GT America';
    src: local('GT America'), url(./fonts/GT-America-Bold-Italic.otf);
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'GT America';
    src: local('GT America'), url(./fonts/GT-America-Bold.otf);
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'GT America';
    src: local('GT America'), url(./fonts/GT-America-Medium-Italic.otf);
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'GT America';
    src: local('GT America'), url(./fonts/GT-America-Medium.otf);
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'GT America';
    src: local('GT America'), url(./fonts/GT-America-Regular-Italic.otf);
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'GT America';
    src: local('GT America'), url(./fonts/GT-America-Regular.otf);
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'GT America';
    src: local('GT America'), url(./fonts/GT-America-Light-Italic.otf);
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'GT America';
    src: local('GT America'), url(./fonts/GT-America-Light.otf);
    font-weight: 300;
    font-style: normal;
}

/*Resets*/
* {
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%; 
  display: flex;
  flex: 1;
  padding: 0;
  margin: 0;
  font-family: GT America, sans-serif;
}

svg {
    height:  100%;
    width: 100%;
}