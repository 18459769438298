.App {
  font-family: sans-serif;
  text-align: center;
}

::backdrop {
  opacity: 0.75;
  background-color: rgba(0, 0, 0, 100);
  cursor: default;
  transition:
    visibility 0.25s,
    opacity 0.25s ease-in-out;
  pointer-events: auto;
}

dialog {
  border: none !important;
  border-radius: calc(5px * var(--ratio));
  box-shadow: 0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
}