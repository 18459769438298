
div#toastInfo .Toastify__toast-icon {
    width: 60px;
    background: #40A3D5;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

div#toastComplete .Toastify__toast-icon {
    width: 60px;
    background: #00BF91;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
div#toastComplete .Toastify__progress-bar {
    background: #40A3D5;
    margin: 1px 2px;
    border: 1px solid #fff;
    border-radius: 3px;
}

div#toastFailed .Toastify__toast-icon {
    width: 60px;
    background: #F05A47;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

div .Toastify__close-button {
    margin: 5px 5px 0 0;
}

div .Toastify__toast {
    padding: 0px;
    
}

div .Toastify__toast-body {
	margin: 0;
	flex: 1 1 auto;
	padding: 0px;
	display: flex;
	align-items: center;
}
